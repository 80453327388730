import './index.css';
import 'antd/dist/antd.css';
const React = require('react');
const ReactDOM = require('react-dom');

const isInternetExplorer = require("./helper/isInternetExplorer").default;

if(isInternetExplorer()){
    ReactDOM.render(<div style={{minHeight: '100vh', minWidth: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h2>Internet Explorer에서는 작동이 안됩니다. Chrome 브라우저로 써주시길 바랍니다.</h2>
    </div>, document.getElementById('root'));
}
else {
    const Routes = require('./routes.js').default;
    const firebase = require("firebase");
    const store = require("./redux/store").default;
    const {signIn, signOut} = require("./redux/auth/main");
    const axios = require('axios');

// Your web app's Firebase configuration
    let khra_b2c = {
        apiKey: "AIzaSyBgI2_HmKKmBcQI66HGRcnSqJy7jTxmXXk",
        authDomain: "khra-backend-2.firebaseapp.com",
        databaseURL: "https://khra-backend-2.firebaseio.com",
        projectId: "khra-backend-2",
        storageBucket: "khra-backend-2.appspot.com",
        messagingSenderId: "965805396224",
        appId: "1:965805396224:web:a104b1def1a861d02463e5",
        measurementId: "G-5DTHSETRVR"
    };

    let khra2_b2b = {
        apiKey: "AIzaSyDMuCq3iwWaX9vHEUuvQ-vXVjqaPZVI9a0",
        authDomain: "khra-backend.firebaseapp.com",
        databaseURL: "https://khra-backend.firebaseio.com",
        projectId: "khra-backend",
        storageBucket: "khra-backend.appspot.com",
        messagingSenderId: "1007584074810",
        appId: "1:1007584074810:web:99598b31b84147f5"
    };

// Initialize Firebase
    firebase.initializeApp(khra_b2c);

    const setAuthHeaders = async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        } catch (err) {
            console.log(err)
        }
    }

    firebase.auth().onAuthStateChanged(async function(user) {
        if (user) {
            console.log('logged in...', user);
            if(!firebase.auth().currentUser) {
                console.log('app logged out right away!');
                return;
            };

            await setAuthHeaders();
            firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.admin) {
                        // Show admin UI.
                        store.dispatch(signIn({admin: true, ...user}));
                    } else {
                        store.dispatch(signIn({admin: false, ...user}));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            console.log('logged out...');
            store.dispatch(signOut());
        }
    });

    firebase.auth().onIdTokenChanged(async function(user) {
        if (user) {
            await setAuthHeaders();
        }
    });

    ReactDOM.render(<Routes />, document.getElementById('root'));
}